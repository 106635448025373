import React, { useState } from 'react';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';

import homebanner from '../../images/landing/bg3.png';
import bgcircle from '../../images/landing/bgcircle.svg';
import transparency from '../../images/landing/transparency.svg';
import quality from '../../images/landing/quality.svg';
import unbiaseddata from '../../images/landing/unbiased-data.svg';
import satisfaction from '../../images/landing/satisfaction.svg';
import money from '../../images/landing/money.svg';
import Play from '../../images/icons/Play.png';
import Userbackground from '../../images/landing/bg2.jpg';
import checkgreen from '../../images/landing/checkgreen.svg';
import comingsoon from '../../images/landing/comingsoon.png';
import { UNBIASED_VIDEO, CONTACT_US } from '../../config/constants';
import Close from '../../images/close.png';
import useFirestore from '../../modules/flamelink-module';
import _ from 'lodash';

import './pricing_styles.scss'
import ContactModal from '../../components/ContactModal';

const vision = [
    {
        type: "Image Classification/Tagging",
        price: "$0.05 (max 5 tags/categories)"
    },
    {
        type: "Image Summarization",
        price: "$0.1 (max 240 characters)"
    },
    {
        type: "Bounding Box/2D Box",
        price: "$0.06/image + 0.03/annotation"
    },
    {
        type: "Polygons",
        price: "$0.06/image + $0.03/annotation"
    },
    {
        type: "Lines & Splines",
        price: "$0.08/image + $0.04/annotation"
    },
    {
        type: "Point Annotations",
        price: "$0.08/image + $0.04/annotation"
    },
    {
        type: "Point Annotations",
        price: "$0.08/image + $0.04/annotation"
    },
]

const naturalLang = [
    {
        type: "Audio to Text Transcription",
        price: "$1(30 sec audio)"
    },
    {
        type: "Basic Sentiment Analysis",
        price: "$0.12(max 50 words)"
    },
    {
        type: "Basic Emotion Detection",
        price: "$0.12(max 50 words)"
    },
    {
        type: "Text Classification",
        price: "$0.12(max 50 words)"
    },
    {
        type: "OCR Transcription",
        price: "$0.08/image + $0.04/annotation"
    },
    {
        type: "Named Entity Recognition",
        price: "$0.1(max 50 words)"
    },
    {
        type: "Translation",
        price: "$0.06(max 50 words)"
    },
    {
        type: "Aspect/Entity Based Sentiment Analysis",
        price: "$0.15(max 50 words)"
    },
    {
        type: "Aspect/Entity Based Emotion Detection",
        price: "$0.15(max 50 words)"
    },
]


export default function Pricing() {
    const docs = useFirestore('fl_content', 'pricing');
    console.log("pricing", docs)
    const [activeBtn, setActiveBtn] = useState(null);
    const [open, setOpen] = useState(false);
    const [openContact, setOpenContact] = useState(false);
    const [openVideo, setOpenVideo] = useState(false);

    const handleOpen = () => {
        setOpenContact(true);
    };

    const handleClose = () => {
        setOpenContact(false);
    };

    const handleOpenVideo = () => {
        setOpenVideo(true);
    };

    const handleCloseVideo = () => {
        setOpenVideo(false);
    };

    return (
        <Layout>
            <SEO title="Pricing" description="" />
            <ContactModal open={openContact} handleClose={handleClose} />
            <ContactModal open={openVideo} handleClose={handleCloseVideo} source={UNBIASED_VIDEO} />
            <div className="pricing-cont">
                <section className="main-banner">
                    <img src={homebanner} alt="Home Banner" className="homeBannerImg" />
                    <Container>
                        <Grid container className="pricing-banner" direction="column" alignItems="center" justify="center">
                            <h2>{!_.isEmpty(docs) && docs[0].title}</h2>
                            <p>{!_.isEmpty(docs) && docs[0].description}</p>
                        </Grid>
                    </Container>
                </section>
                <section className="pricing-plans">
                    <h3>{!_.isEmpty(docs) && docs[0].subHeading}</h3>
                </section>
                <section className="pricing-plans">
                    <Grid container direction="column" className="heading">
                        <h5>DATA annotation</h5>
                        <span></span>
                    </Grid>
                    <div className="pricing-list-cont">
                        <Grid container className="list-container">
                            {!_.isEmpty(docs) && docs[0].hasOwnProperty('services') &&
                                <Grid item className="grids">
                                    <div className="gridBox g1">
                                        <div className="sec1">
                                            <h4>{docs[0].services[0].demand.title}</h4>
                                        </div>
                                        <div className="sec2">
                                            <div className="boxNew">
                                                <Button className={activeBtn === 0 && "btnSolid"} onClick={() => { setOpen(true); setActiveBtn(0) }}>{docs[0].services[0].demand.category[0].title}</Button>
                                            </div>
                                            <div className="boxNew" >
                                                <Button className={activeBtn === 1 && "btnSolid"} onClick={() => { setOpen(true); setActiveBtn(1) }}>{docs[0].services[0].demand.category[1].title}</Button>
                                            </div>
                                        </div>
                                        <div className="sec3">
                                            <Button onClick={handleOpenVideo} target="_blank"><img src={Play} alt="Play" width="20" />What’s unbiased?</Button>
                                        </div>
                                    </div>
                                </Grid>
                            }
                            {
                                !_.isEmpty(docs) && docs[0].hasOwnProperty('services') && docs[0].services[0].plans.map((val, i) => {
                                    return (
                                        <Grid item className="grids">
                                            <div className={i === 0 ? "gridBox g2" : "gridBox g3"}>
                                                <div className="comingsoon">
                                                    <img src={comingsoon} alt="Coming Soon" />
                                                </div>
                                                <div className="sec1">
                                                    <h5>{val.title}</h5>
                                                    <h6>{val.period}</h6>
                                                    <h2>{val.price}</h2>
                                                    <p>{val.units}</p>
                                                    <Button className="greenBtn">{val.offer}</Button>
                                                </div>
                                                <div className="sec2">
                                                    <div className="boxNew">
                                                        <Button className="boxNew">COMPUTER VISION</Button>
                                                    </div>
                                                    <div className="boxNew" >
                                                        <Button >NATURAL LANGUAGE</Button>
                                                    </div>
                                                </div>
                                                <div className="sec3">
                                                    <Button>GET STARTED</Button>
                                                </div>
                                            </div>
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                    </div>
                    <div>
                        {
                            activeBtn === 0 &&
                            <div className="modelCont model1">
                                <img src={Close} alt="closeIcon" className="iconClose" onClick={() => { setActiveBtn(null) }} />
                                {
                                    !_.isEmpty(docs) && docs[0].hasOwnProperty('services') && docs[0].services[0].demand.category[0].subCategory.map((val, i) => {
                                        return (
                                            <div key={i} className="listCont">
                                                <div className="licon"><span><img src={checkgreen} alt="check" /></span></div>
                                                <div className="ltxt">
                                                    <h6>{val.type}</h6>
                                                    <p>{val.price}</p>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        }
                        {activeBtn === 1 &&
                            <div className="modelCont model2">
                                <img src={Close} alt="closeIcon" className="iconClose" onClick={() => { setActiveBtn(null) }} />
                                {
                                    !_.isEmpty(docs) && docs[0].hasOwnProperty('services') && docs[0].services[0].demand.category[1].subCategory.map((val, i) => {
                                        return (
                                            <div key={i} className="listCont">
                                                <div className="licon"><span><img src={checkgreen} alt="check" /></span></div>
                                                <div className="ltxt">
                                                    <h6>{val.type}</h6>
                                                    <p>{val.price}</p>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        }
                    </div>
                </section>



                <section className="pricing-plans">
                    <Grid container direction="column" className="heading">
                        <h5>DATA COLLECTION</h5>
                        <span></span>
                    </Grid>
                    <div className="pricing-list-cont">
                        <Grid container className="list-container">
                            <Grid item className="grids">
                                <div className="gridBox g1">
                                    <div className="sec1">
                                        <h4>ON DEMAND</h4>
                                    </div>
                                    <div className="sec2">
                                        {
                                            !_.isEmpty(docs) && docs[0].hasOwnProperty('services') && docs[0].services[1].demand.category[0].subCategory.map((val, i) => {
                                                return (<div className="listCont">
                                                    <div className="licon">
                                                        <span>
                                                            <img src={checkgreen} alt="check" width="10" />
                                                        </span>
                                                    </div>
                                                    <div className="ltxt">
                                                        <h6>{val.type}</h6>
                                                        <p>{val.price}</p>
                                                    </div>
                                                </div>)
                                            })
                                        }
                                    </div>
                                    <div className="sec3">
                                        <Button onClick={handleOpenVideo} target="_blank"><img src={Play} alt="Play" width="20" />What’s unbiased?</Button>
                                    </div>
                                </div>
                            </Grid>
                            {
                                !_.isEmpty(docs) && docs[0].hasOwnProperty('services') && docs[0].services[1].plans.map((val, i) => {
                                    return (
                                        <Grid item className="grids">
                                            <div className={i === 0 ? "gridBox g2" : "gridBox g3"}>
                                                <div className="comingsoon">
                                                    <img src={comingsoon} alt="Coming Soon" />
                                                </div>
                                                <div className="sec1">
                                                    <h5>{val.title}</h5>
                                                    <h6>{val.period}</h6>
                                                    <h2>{val.price}</h2>
                                                    <p>{val.units} </p>
                                                    <Button className="greenBtn">{val.offer}</Button>
                                                </div>
                                                <div className="sec2">
                                                    {
                                                        val.category[0].subCategory.map((val, index) => {
                                                            return (
                                                                <div className="listCont m20">
                                                                    <div className="licon">
                                                                        <span>
                                                                            <img src={checkgreen} alt="check" width="10" />
                                                                        </span>
                                                                    </div>
                                                                    <div className="ltxt">
                                                                        <h6>{val.type}</h6>
                                                                        <p>{val.price}</p>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                                <div className="sec3">
                                                    <Button>GET STARTED</Button>
                                                </div>
                                            </div>
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                    </div>

                </section>

                <section className="enterprise">
                    <h4>ENTERPRISE</h4>
                    <p>Ideal for Strategic AI Initiatives and High Volumes that require Guaranteed Turnaround Times and Quality</p>
                    <Button onClick={handleOpen} target="_blank">CONTACT US</Button>
                </section>

                <section className="whyChoose">
                    <img src={bgcircle} alt="Background" className="bgCircle" />
                    <section container className="why-choose-cont">
                        <h2>Why choose unbiased?</h2>
                        <p>Unbiased is innovating to solve many of the current problems in the Artifical Intelligence & Machine Learning industry such as Transparency, Bias and Quality of Training Data. Unbiased Data Marketplace platform will act as a gateway to Data Annotations, knowledge sharing, collaborations & futuristic innovation.</p>

                        <div className="circleContLarge">
                            <div className="cCont">
                                <div className="circle">
                                    <img src={transparency} alt="Transparency" width="36" />
                                </div>
                                <h6>TRANSPARENCY</h6>
                            </div>
                            <div className="cCont">
                                <div className="circle">
                                    <img src={quality} alt="Quality Assurance" width="36" />
                                </div>
                                <h6>QUALITY ASSURANCE</h6>
                            </div>
                            <div className="cCont">
                                <div className="circle">
                                    <img src={unbiaseddata} alt="Unbiased Data" width="34" />
                                </div>
                                <h6>UNBIASED DATA</h6>
                            </div>
                        </div>

                        <div className="circleContSmall">
                            <div className="cCont">
                                <div className="circle">
                                    <img src={satisfaction} alt="Worker Satisfaction" width="36" />
                                </div>
                                <h6>WORKER SATISFACTION</h6>
                            </div>
                            <div className="cCont">
                                <div className="circle">
                                    <img src={money} alt="Spam Free Accounts" width="36" />
                                </div>
                                <h6>SPAM FREE ACCOUNTS</h6>
                            </div>
                        </div>


                        <Grid container justify="center" className="btn-cont">
                            <Button className="btn-solid" onClick={handleOpenVideo} target="_blank"><img src={Play} alt="play" width="20" /> What’s unbiased?</Button>
                            <Button className="btn-bordered">LEARN MORE</Button>
                        </Grid>
                    </section>
                </section>


                <section className="happy-users">
                    <img src={Userbackground} alt="Background" className="happy-user-bg" />
                    <Grid container direction="column" alignItems="center" justify="center" className="formCont">
                        <h2>JOIN THE LIST OF OUR HAPPY USERS</h2>
                        <Button onClick={handleOpenVideo} target="_blank">
                            <img src={Play} alt="play" width="20" />
                            What’s unbiased?
                        </Button>
                    </Grid>
                </section>



            </div >

        </Layout >
    );
}
